@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), url('./assets/fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: normal;
    src: local('Montserrat Italic'), url('./assets/fonts/Montserrat-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat Medium';
    font-style: normal;
    font-weight: 500;
    src: local('Montserrat Medium'), url('./assets/fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat Medium';
    font-style: italic;
    font-weight: 500;
    src: local('Montserrat Medium Italic'), url('./assets/fonts/Montserrat-MediumItalic.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), url('./assets/fonts/Montserrat-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: local('Montserrat Bold Italic'), url('./assets/fonts/Montserrat-BoldItalic.woff') format('woff');
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}